import React  from 'react'
import ReactWhatsapp from "react-whatsapp";

import necklace from "../images/NECKLACE 1.jpg";

import necklacedesign from "../images/necklage design.jpg";
import necklaceberhampore from "../images/necklace berhampore.jpg";

import necklacekhagra from "../images/necklacemb.jpg";
import necklacelayer from "../images/necklace layer.jpg";
import necklaceLEAF from "../images/NECKLACE LEAF.jpg";
import necklacgold from "../images/necklace update.jpg";
import necklacecircle from "../images/necklace circle.jpg";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import necklacegold from "../images/NECKLACE 9.jpg";
import necklacesankhadesign from "../images/gold_necklace.jpg";
import necklacbutterfly from "../images/NECKLACE BUTTEFLY.jpg";
import necklaceleaf from "../images/leaf_necklace.jpg";
import necklacewithjhumka from  "../images/necklace with jhumka.jpg";
import necklacedesigntrendign from "../images/best design necklace.jpg";
import necklacwithearring from "../images/necklace with earring.jpg";
import circlenecklacewithearrign from "../images/circle necklace with earring.jpg";
import necklacepage from "../images/necklacepage.jpg";
import necklacemina from "../images/necklace mayr.jpg";
import necklacelatest from "../images/necklace latest.jpg";
import goldNecklace from "../images/goldnecklacembdhar.jpg";


function Necklacehome() {
    return (
        <div>
          
    
        
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                   
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklace} className="card-img-top" alt="necklace"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-6  mt-3">
                           
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklacedesign} className="card-img-top" alt="necklacedesign"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={necklaceberhampore} className="card-img-top" alt="necklaceberhampore"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Necklace</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacekhagra} className="card-img-top" alt="necklacekhagra"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Necklace                                            </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={necklacelayer} className="card-img-top" alt="necklacelayer"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklaceLEAF} className="card-img-top" alt="necklaceLEAF"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacgold} className="card-img-top" alt="necklacgold"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Necklace </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacecircle} className="card-img-top" alt="Necklacedesign"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Necklace</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={necklacegold} className="card-img-top" alt="necklacelayer"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklacesankhadesign} className="card-img-top" alt="necklace"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacbutterfly} className="card-img-top" alt="necklacgold"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Necklace </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={necklaceleaf} className="card-img-top" alt="necklaceleaf"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Necklace</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={necklacewithjhumka} className="card-img-top" alt="necklacewithjhumka"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklacedesigntrendign} className="card-img-top" alt="necklace"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacwithearring} className="card-img-top" alt="necklacwithearring"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Necklace </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={circlenecklacewithearrign} className="card-img-top" alt="necklaceleaf"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Necklace</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={necklacepage} className="card-img-top" alt="necklacewithjhumka"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={necklacemina} className="card-img-top" alt="necklace"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Necklace</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={necklacelatest} className="card-img-top" alt="necklacwithearring"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Necklace </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={goldNecklace} className="card-img-top" alt="necklace"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Necklace</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 
            <br />

            <br />
        </div>
    )
}

export default Necklacehome