
import React from 'react'
import logo from "../images/logomb.jpg";
import { Link } from "react-router-dom";
import Youtube from "../images/youtube.jpg";


import "./Header.css";

function Nvbar() {
  return (
    <div>
      <div className="fixed-top">
        <div className="mbdharbg">
          <div className="album py-1">

            <div className="container">

              <div className="row ">

                <div className="col-md-10" >
                  <div className="d-flex justify-content-between align-items-center">

                    <a className="btn-gradnav" href="tel: +91 9093168565">Call Now </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@sonarsansar8785">
                      <img src={Youtube} height="30px" width="120px" style={{ pading: "2px" }} alt="img" />
                    </a>



                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={logo} className="img-fluid me-5" width="120" height="28" alt="Logos" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>Home</Link>
                </li>
         
                <li className="nav-item dropdown">
                  <a style={{ color: "maroon" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Earrings
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">Update Soon..</a></li>
                   
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a style={{ color: "maroon" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pendants
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/Locketpage" className="dropdown-item" href="#">Locket</Link></li>
              
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/necklace-page" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>Necklace</Link>
                </li>
                <li className="nav-item dropdown">
                  <a style={{ color: "maroon" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Product
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/ring" className="dropdown-item" >Ring</Link></li>
                    <li><Link to="/dul" className="dropdown-item" >Dul</Link></li>
                    <li><Link to="/bala" className="dropdown-item">Bala</Link></li>
                    <li><Link to="/chain" className="dropdown-item">Chain</Link></li>
                    <li><Link to="/chik" className="dropdown-item">Chik</Link></li>
                    <li><Link to="/bracelet" className="dropdown-item">Bracelet</Link></li>
                    <li><Link to="/necklace" className="dropdown-item">Necklece</Link></li>
                    <li><Link to="/locket" className="dropdown-item">Locket</Link></li>
                    <li><Link to="/solidchuri" className="dropdown-item">Solid Churi</Link></li>
                    <li><Link to="/jhumka" className="dropdown-item">Jhumka</Link></li>
                    <li><Link to="/bresletpola" className="dropdown-item">Breslet Pola</Link></li>
                    <li><Link to="/babybala" className="dropdown-item">Baby Bala</Link></li>
                    <li><Link to="/bawtry" className="dropdown-item">Bawty</Link></li>
                    <li><Link to="/mantasa" className="dropdown-item">Mantasa</Link></li>
                    <li><Link to="/sankha" className="dropdown-item">Sankha</Link></li>
                    <li><Link to="/bresletpola" className="dropdown-item">Breslet Pola</Link></li>
                    <li><Link to="/breslet" className="dropdown-item">Chur</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link to="/konbala" className="dropdown-item" >Kan Bala</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/career" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>Career</Link>
                </li>
                
                <li className="nav-item">
                  <Link to="/contact" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>Contact</Link>
                </li>
              </ul>


              {/* <button className="btn btn-outline-success me-3" type="submit"><div align='end'><a href='https:/www.mrindiancoder.com/'>
                              <img src='https://www.free-website-hit-counter.com/c.php?d=9&id=148623&s=55' border='0'
                               alt='Free Website Hit Counter'/></a><br / ><small>
                               </small></div> </button> */}

              {/* <div align='end'><a href='https:/www.mrindiancoder.com/'>
                              <img src='https://www.free-website-hit-counter.com/c.php?d=9&id=148623&s=55' border='0'
                               alt='Free Website Hit Counter'/></a><br / ><small>
                               </small></div> */}


            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Nvbar
